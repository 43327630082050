import HYRequest from './request'
import { BASE_URL, TIME_OUT } from './request/config'
// import localCache from '@/utils/cache'
const htRequest = new HYRequest({
  baseURL: BASE_URL,
  timeout: TIME_OUT,
  // headers: 'application/x-www-form-urlencoded',
  interceptors: {
    requestInterceptor: (config) => {
      // 携带token的拦截
      // const token = localCache.getCache('token')
      // if (token) {
      //   config.headers.Authorization = `Bearer ${token}`
      // }
      // console.log('请求拦截成功')
      return config
    },
    requestInterceptorCatch: (err) => {
      // console.log('请求拦截失败')
      return err
    },
    responseInterceptor: (config) => {
      // console.log('回调拦截成功')
      return config
    },
    responseInterceptorCatch: (err) => {
      // console.log('回调拦截失败')
      return err
    }
  }
})

export default htRequest
