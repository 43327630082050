import { Module } from 'vuex'
// import localCache from '@/utils/cache'

import { getlist, getdetail, getWallList } from '@/service/article/article'
import { IArticleState } from './types'

// <S,R> S = 模块的泛型，R = 根的泛型
const articleModule: Module<IArticleState, any> = {
  namespaced: true,
  state() {
    return {
      list: '',
      detail: '',
      wallList: []
    }
  },
  mutations: {
    getList(state, data: any) {
      state.list = data
    },
    getDetail(state, data: any) {
      state.detail = data
    },
    getWallList(state, data: any) {
      state.wallList = data
    }
  },
  getters: {},
  actions: {
    async getListAction({ commit }, payload: any) {
      const list: any = await getlist()
      const data = list.data
      commit('getList', data)
    },
    async getDetailAction({ commit }, payload: any) {
      const id = payload
      const detail: any = await getdetail(id)
      const data = detail.data
      commit('getDetail', data)
    },
    async getWallListAction({ commit }) {
      const res: any = await getWallList()
      const data = res.data
      commit('getWallList', data)
    }
  }
}

export default articleModule
