<template>
  <div class="footer ignores">
    <div class="gongan ignores">
      <a
        href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=31010502006044"
        target="_blank"
      >
        <img src="~@/assets/img/beian_icon.png" alt="" />
        <div>沪公网安备 31010502006044号</div>
      </a>
    </div>
    <span>
      <a href="https://beian.miit.gov.cn" target="_blank"
        >沪ICP备2021023617号-1</a
      ></span
    >
  </div>
</template>
<script lang="ts">
import { defineComponent } from 'vue'
export default defineComponent({
  setup() {
    return {}
  }
})
</script>
<style scoped lang="scss">
.footer {
  width: 100%;
  height: 60px;
  background-color: #000;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
}
.gongan {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 15px;
  a {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  img {
    width: 10px;
    margin-right: 2px;
    @media screen and (max-width: 768px) {
      width: 25px;
    }
  }
}
.ignores {
  font-size: 14px;
  @media screen and (max-width: 768px) {
    font-size: 12px;
  }
}
</style>
