<template>
  <div class="home">
    <div class="wrap">
      <div class="stickdiv stickdiv1">
        <page1
          :originPercent1="originPercent1"
          ref="page1"
          class="page1"
        ></page1>
      </div>
      <div class="stickdiv">
        <page2 class="page2" :originPercent2="originPercent2"></page2>
      </div>
      <div class="stickdiv"><page3 class="page3"></page3></div>
      <div class="stickdiv stickdiv4"><page4 class="page4"></page4></div>
      <mfooter></mfooter>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref, onMounted, watch } from 'vue'
import { useRoute } from 'vue-router'
import page1 from './cpns/Page1.vue'
import page2 from './cpns/Page2.vue'
import page3 from './cpns/Page3.vue'
import page4 from './cpns/Page4.vue'
import mfooter from '@/components/footer.vue'
// import Swiper core and required modules

export default defineComponent({
  name: 'Home',
  components: { page1, page2, page3, page4, mfooter },
  setup() {
    const windowHeight = ref(window.innerHeight)
    const page1 = ref()
    const page2 = ref(null)
    const page4 = ref()
    const scrollT = ref(0)
    const originPercent1 = ref(0)
    const originPercent2 = ref(0)
    const route = useRoute()
    const isback = route.query.isback
    const isTop = route.query.top

    onMounted(() => {
      const documentH = ref(document.documentElement.scrollHeight)

      window.onscroll = function () {
        scrollT.value = document.documentElement.scrollTop
      }

      if (isback) {
        const dom = document.querySelector('.page4')
        const scrollHeight = isTop ? windowHeight.value * 6 : documentH.value
        console.log(scrollHeight)

        if (scrollHeight && dom != null) {
          setTimeout(() => {
            document.documentElement.scrollTo(0, scrollHeight)
          }, 100)
        }
      }
    })
    watch(scrollT, (newV) => {
      const w = windowHeight.value
      if (newV >= 0 && newV < w) {
        originPercent1.value = (newV / w) * 100
      } else if (newV >= w * 2 && newV < w * 3) {
        originPercent2.value = ((newV - 2 * w) / w) * 100
      }
      // max
      if (newV > w) {
        originPercent1.value = 100
      }
      if (newV > 3 * w) {
        originPercent2.value = 100
      }
      if (newV == 0) {
        originPercent1.value = 0
      }
      if (newV < 2 * w) {
        originPercent2.value = 0
      }
    })

    return {
      page1,
      page2,
      page4,
      originPercent1,
      originPercent2
    }
  }
})
</script>
<style lang="scss" scoped>
.home {
  background-color: #000;
}
.wrap {
  // height: 300vh;
  // position: absolute;
}
.redBlock {
  width: 200px;
  height: 200px;
  background-color: red;
  position: sticky;
  top: 100px;
  z-index: 999;
}
.page1 {
  position: sticky;
  top: 0px;
}
.stickdiv {
  height: 200vh;
  width: 100%;
}
.stickdiv4 {
  height: auto;
}
.page2 {
  position: sticky;
  top: 0px;
}
.page3,
.page4 {
  position: sticky;
  top: 0px;
}
</style>
