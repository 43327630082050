import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import Home from '../views/pc/home/Home.vue'
import mHome from '../views/m/home/home.vue'

// import localCache from '@/utils/cache'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    component: Home
  },
  {
    path: '/m/',
    component: mHome
  },
  {
    path: '/home',
    name: 'Home',
    component: Home
  },
  {
    path: '/worklist',
    name: 'worklist',
    component: () => import('@/views/pc/worklist/worklist.vue')
  },
  {
    path: '/workdetail/:id',
    name: 'workdetail',
    component: () => import('@/views/pc/workdetail/workdetail.vue')
  },
  {
    path: '/m/home',
    name: 'mhome',
    component: mHome
  },
  {
    path: '/m/worklist',
    name: 'mworklist',
    component: () => import('@/views/m/worklist/worklist.vue')
  },
  {
    path: '/m/workdetail/:id',
    name: 'mworkdetail',
    component: () => import('@/views/m/workdetail/workdetail.vue')
  }
]

const router = createRouter({
  history: createWebHistory('/cohere/'),
  routes,
  scrollBehavior(to, from, savedPosition) {
    console.log(savedPosition)

    if (savedPosition) {
      return savedPosition
    } else {
      return { top: 0 }
    }
  }
})

router.beforeEach((to, from, next) => {
  const ismobile = IsPc()
  const topath = to.path
  console.log(ismobile)

  if (ismobile) {
    if (topath.includes('/m/')) {
      next()
    } else {
      next({ path: `/m${to.path}` })
    }
  } else {
    if (topath.includes('/m/')) {
      const url = to.path.split('/m')[1]
      next({ path: `${url}` })
    } else {
      next()
    }
  }
})

//是否是移动端
function IsPc() {
  const userAgent = navigator.userAgent
  const Agents = [
    'Android',
    'iPhone',
    'SymbianOS',
    'Windows Phone',
    'iPad',
    'iPod'
  ]
  console.log('userAgent:', userAgent)
  return Agents.some((i) => {
    return userAgent.includes(i)
  })
}
export default router
