
import { defineComponent, ref, watchEffect } from 'vue'
export default defineComponent({
  props: {
    pageNumber: Number,
    originPercent1: Number
  },
  emits: ['scrollEvent'],
  setup(props) {
    const logo2Ref: any = ref(null)
    const originPercent = ref()
    const transX = ref()
    watchEffect(() => {
      originPercent.value = props.originPercent1
      const w = logo2Ref.value?.offsetWidth
      transX.value = `${originPercent.value}% + ${
        (w * originPercent.value) / 100
      }px`
    })

    return {
      logo2Ref,
      transX
    }
  }
})
