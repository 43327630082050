import { ref, onMounted, watch, nextTick } from 'vue'

export default function (wallList: any) {
  // Register IntersectionObserver
  const guideActiveIndex = ref(0)
  onMounted(() => {
    watch(wallList, (newval) => {
      const io = new IntersectionObserver(
        (entries) => {
          entries.forEach((entry, index) => {
            if (entry.intersectionRatio > 0.5) {
              const className = entry.target.classList.value
              if (className.includes('works')) {
                guideActiveIndex.value = 0
              } else if (className.includes('cohere-family')) {
                guideActiveIndex.value = 1
              } else if (className.includes('aboutus')) {
                guideActiveIndex.value = 2
              } else if (className.includes('contactus')) {
                guideActiveIndex.value = 3
              }
            }
          })
        },
        {
          threshold: [0.5]
        }
      )
      if (newval.length > 0) {
        nextTick(() => {
          const boxElList = document.querySelectorAll('.guide-item')
          boxElList.forEach((el) => {
            io.observe(el)
          })
        })
      }
    })
  })
  return guideActiveIndex
}
