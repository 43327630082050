import hyRequest from '../index'

enum ArticleAPI {
  getlist = '/getGoodList',
  getdetail = '/getGoodDetail',
  getWallList = '/getWallList'
  // LoginUserInfo = '/users/', // 用法: /users/1
  // UserMenus = '/role/' // 用法: role/1/menu
}
export function getlist() {
  return hyRequest.post({
    url: ArticleAPI.getlist,
    showLoading: false
  })
}

export function getdetail(id: number) {
  console.log(id)

  return hyRequest.post({
    url: ArticleAPI.getdetail,
    showLoading: false,
    data: { id }
  })
}

export function getWallList() {
  return hyRequest.post({
    url: ArticleAPI.getWallList,
    showLoading: false,
    data: {}
  })
}
