import { createStore } from 'vuex'
import article from './article/article'

const store = createStore<any>({
  state: () => {
    return {}
  },
  mutations: {},
  actions: {},
  modules: { article }
})

export default store
