import { nextTick, ref, onMounted } from 'vue'

export default function (dom: any) {
  const realboxHeight = ref(0)
  const realboxWidth = ref(0)

  onMounted(() => {
    nextTick(() => {
      caclRealWH(dom, realboxHeight, realboxWidth)
    })

    window.addEventListener('resize', () => {
      caclRealWH(dom, realboxHeight, realboxWidth)
    })
  })

  return { realboxHeight, realboxWidth }
}
function caclRealWH(dom: any, realboxHeight: any, realboxWidth: any) {
  const fatherh = dom.value.offsetHeight
  const fatherw = dom.value.offsetWidth
  const v = _caclWH(fatherw, fatherh)
  if (v === 'h') {
    realboxHeight.value = fatherh
    realboxWidth.value = (1816 / 966) * fatherh
  } else {
    realboxHeight.value = (966 / 1816) * fatherw
    realboxWidth.value = fatherw
  }
}

function _caclWH(w: number, h: number) {
  const origin = 966 / 1816
  const real = h / w
  if (real > origin) {
    return 'w'
  } else if (real < origin) {
    return 'h'
  }
}
