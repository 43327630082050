<template>
  <guide :guideActiveIndex="guideActiveIndex"></guide>
  <div class="page4" id="page4">
    <div class="container">
      <div class="works guide-item" id="work">
        <ul>
          <li v-for="item in list" :key="item.id" @click="toDetail(item.id)">
            <img :src="'https://www.coherehouse.com' + item.tmb1" alt="" />
            <div class="text">{{ item.title }}</div>
          </li>
        </ul>
        <div class="more" @click="toList">
          <img src="~@/assets/img/more.png" alt="" srcset="" />
        </div>
      </div>
      <div
        class="photoList cohere-family guide-item"
        id="cohere-family"
        v-if="wallList.length > 0"
      >
        <ul>
          <li class="item" v-for="(item, index) of wallList" :key="index">
            <swiper
              :loop="true"
              :autoplay="{
                delay: item.delay * 1000
              }"
            >
              <swiper-slide
                class="slider"
                v-for="(sitem, index) in item.photos"
                :key="index"
                ><div class="box">
                  <img
                    :src="'https://www.coherehouse.com' + sitem"
                    alt=""
                    srcset=""
                  /></div
              ></swiper-slide>
            </swiper>
          </li>
          <!-- <li class="item" v-for="(item, index) in wallList" :key="index">
            <img :src="'https://www.coherehouse.com' + item" alt="" />
          </li> -->
        </ul>
      </div>
      <div class="founder cohere-family guide-item">
        <ul>
          <li>
            <img src="~@/assets/img/founder1.jpg" alt="" />
            <div class="imf">
              <div class="name">YUNYUN GU</div>
              <div class="title">CO-FOUNDER</div>
              <div class="intro">
                YUNYUN HAS MANY YEARS OF WORKING EXPERIENCE IN VIDEO,
                PHOTOGRAPHY AND OTHER DOCUMENTARY FILM SHOOTING, ESPECIALLY IN
                THE FIELD OF SPORTS AND SPORTS CULTURE HAS CONSIDERABLE
                EXPERIENCE
              </div>
            </div>
          </li>
          <li>
            <img src="~@/assets/img/founder2.jpg" alt="" />
            <div class="imf">
              <div class="name">MENMEN XIAO</div>
              <div class="title">CO-FOUNDER</div>
              <div class="intro">
                MENMEN HAS BEEN CONTINUOUSLY PROVIDING HIGH-QUALITY CREATIVE
                CONSULTING SERVICES AND VISUAL CONTENT FOR MANY *GIANTS FROM THE
                *INDUSTIRES. WITH EXTENSIVE EXPERIENCE IN THE SPORTS, FASHION
                AND YOUTH CULTURE INDUSTIRIES
              </div>
            </div>
          </li>
          <li>
            <img src="~@/assets/img/founder3.jpg" alt="" />
            <div class="imf">
              <div class="name">KARTOON FAN</div>
              <div class="title">CO-FOUNDER</div>
              <div class="intro">
                KARTOON WORKED FOR THE ONE OF THE WORLD’S LARGEST STREET CULTURE
                MEDIA, WHERE HE WAS A CREATIVE EDITOR. WITH YEARS OF EXPERIENCE
                IN MEDIA PLATFORM. AND VISUAL CREATIVITY AND YOUTH CULTURAL
                KNOWLEDGE
              </div>
            </div>
          </li>
        </ul>
      </div>
      <div class="aboutus guide-item" id="aboutus">
        <div class="title">ABOUT US</div>
        <div class="en text">
          <p>COHERE,BORN IN 2019,</p>
          <p>A FULL- PROCESS CREATIVE EXECUTION AGENCY</p>
          <p>COMPOSED OF A GROUP PROFESSIONALS CREATORS</p>
          <p>WITH EXTENSIVE EXPERIENCE</p>
          <p>THE SPORTS INDUSTRY</p>
          <p>AND YOUTH CULTURE FIELD.</p>
        </div>
        <div class="cn text">
          <p>COHERE诞生于2019年，是一个全流程创意执行团队，</p>
          <p>由一群在专业运动领域</p>
          <p>与青年街头文化领域拥有从业经验的创意人士组成</p>
          <p>自成立以来，</p>
          <p>我们一直持续不断地为品牌客户们</p>
          <p>提供创意咨询与内容制作服务。</p>
        </div>
        <div class="brandlist">
          <img src="~@/assets/img/logolistm.png" alt="" />
        </div>
      </div>
      <div class="contactus guide-item" id="contactus">
        <div class="box">
          <div class="logo">
            <img src="~@/assets/img/logo.png" alt="" srcset="" />
          </div>
          <div class="contactbox">
            <div class="DWB t1">CONTACT US</div>
            <div class="HEL t2">
              <a href="mailto:MENMEN.XIAO@COHERE-CO.COM"
                >MENMEN.XIAO@COHERE-CO.COM</a
              >
            </div>
          </div>
          <div class="joinbox">
            <div class="DWB t1">JOIN US</div>
            <div class="HEL t2">
              <a href="mailto:MENMEN.XIAO@COHERE-CO.COM"
                >MENMEN.XIAO@COHERE-CO.COM</a
              >
            </div>
          </div>
          <div class="followbox">
            <div class="DWB t1">FOLLOW US</div>
            <div>
              <ul>
                <li><img src="~@/assets/img/wechat.png" alt="" /></li>
                <li><img src="~@/assets/img/sina.png" alt="" /></li>
                <li><img src="~@/assets/img/ins.png" alt="" /></li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script lang="ts">
import { defineComponent, computed } from 'vue'
import Guide from './guide.vue'
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'

import useGuide from './hooks/useGuide'

import { Swiper, SwiperSlide } from 'swiper/vue'
// import Swiper core and required modules
import SwiperCore, { Autoplay } from 'swiper'

// install Swiper modules
SwiperCore.use([Autoplay])
// import type SwiperType from 'swiper/types/swiper-class'
import 'swiper/swiper.scss'
export default defineComponent({
  components: { Swiper, SwiperSlide, Guide },
  setup() {
    // 获取数据
    const store = useStore()
    store.dispatch('article/getListAction')
    store.dispatch('article/getWallListAction')
    const list = computed(() => {
      return store.state.article.list.slice(0, 8)
    })
    const wallList = computed(() => {
      return store.state.article.wallList.slice(0, 8)
    })

    const router = useRouter()

    const toDetail = (index: number) => {
      router.replace({
        name: 'mworkdetail',
        params: {
          id: index
        }
      })
    }
    const toList = () => {
      router.replace({
        name: 'mworklist'
      })
    }

    // 导航所需
    const guideActiveIndex = useGuide(wallList)

    return { toDetail, toList, list, wallList, guideActiveIndex }
  }
})
</script>
<style scoped lang="scss">
.page4 {
  width: 100%;
  background-color: #000;
  padding-top: 196px;
  position: relative;
  transform: translateZ(1px);
  // height: 100%;
  .container {
    width: 100%;
    padding: 0 40px;
    margin: 0 auto;
    padding-bottom: 11vw;
    .works {
      width: 100%;
      font-size: 20px;
      letter-spacing: 0px;
      // letter-spacing: -1px;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      ul {
        display: grid;
        width: 100%;
        height: 100%;
        grid-template-columns: repeat(2, 1fr);
        gap: 60px 40px;
        align-items: start;

        li {
          color: #fff;
          display: flex;
          align-items: center;
          justify-content: center;
          flex-direction: column;
          .text {
            // height: 50px;
            margin-top: 18px;
            text-align: center;
            line-height: 1.3;
            font-size: 24px;
            font-weight: 600;
          }
        }
      }
      .more {
        width: 264px;
        margin: 100px auto 0;
        cursor: pointer;
      }
    }
    .photoList {
      width: 100%;
      margin: 300px 0 100px;
      ul {
        // display: grid;
        // width: 100%;
        // height: 100%;
        // grid-template-columns: repeat(2, 1fr);
        // gap: 10px 10px;
        // align-items: start;
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-wrap: wrap;
        li {
          color: #fff;
          // display: flex;
          // align-items: center;
          // justify-content: center;
          // flex-direction: column;
          width: 49%;
          margin-bottom: 15px;
          img {
            display: block;
          }
        }
      }
    }
    .founder {
      width: 100%;
      margin-top: 240px;
      ul {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-direction: column;
        // grid-template-columns: repeat(3, 1fr);
        li {
          width: 404px;
          color: #fff;
          margin-top: 100px;
          .imf {
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            font-size: 24px;
            .name {
              margin-top: 40px;
              margin-bottom: 18px;
              font-weight: 600;
            }
            .intro {
              // width: 60%;
              margin-top: 40px;
              line-height: 1.8;
              text-align: center;
            }
          }
        }
      }
    }
    .aboutus {
      color: #fff;
      width: 660px;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      margin: 180px 0;
      font-weight: bold;
      .title {
        font-size: 40px;
        margin-bottom: 60px;
      }
      .text {
        font-size: 24px;
        margin-bottom: 40px;
        line-height: 1.5;
        text-align: center;
        p {
          white-space: nowrap;
        }
      }
      .brandlist {
        width: 100%;
        margin-top: 140px;
      }
    }
    .contactus {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-top: 240px;
      .box {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        .logo {
          width: 110px;
          margin-bottom: 50px;
        }
        .contactbox,
        .joinbox,
        .followbox {
          text-align: center;
          margin-bottom: 52px;

          .t1 {
            color: #b51700;
            font-size: 34px;
            margin-bottom: 20px;
          }
          .t2 {
            font-size: 24px;
            color: #fff;
            a {
              text-decoration: none;
            }
          }
        }
        .followbox {
          // width: 100%;
          ul {
            display: flex;
            align-items: center;
            justify-content: space-between;
            li {
              width: 60px;
            }
          }
        }
      }
    }
  }
}
.footer {
  width: 100%;
}
</style>
