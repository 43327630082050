import { nextTick, ref, onMounted } from 'vue'
const ORIGINW = 672
const ORIGINH = 1362

export default function (dom: any) {
  const realboxHeight = ref(0)
  const realboxWidth = ref(0)

  onMounted(() => {
    nextTick(() => {
      setTimeout(() => {
        caclRealWH(dom, realboxHeight, realboxWidth)
      }, 600)
    })

    window.addEventListener('resize', () => {
      caclRealWH(dom, realboxHeight, realboxWidth)
    })
  })

  return { realboxHeight, realboxWidth }
}
function caclRealWH(dom: any, realboxHeight: any, realboxWidth: any) {
  const fatherh = dom.value.offsetHeight
  const fatherw = dom.value.offsetWidth
  const v = _caclWH(fatherw, fatherh)
  console.log(v)

  if (v === 'h') {
    realboxHeight.value = fatherh
    realboxWidth.value = (ORIGINW / ORIGINH) * fatherh
  } else {
    realboxHeight.value = (ORIGINH / ORIGINW) * fatherw
    console.log(fatherw)
    console.log(fatherw)

    realboxWidth.value = fatherw
  }
}

function _caclWH(w: number, h: number) {
  const origin = ORIGINH / ORIGINW
  const real = h / w
  if (real > origin) {
    return 'w'
  } else if (real < origin) {
    return 'h'
  }
}
