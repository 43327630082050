import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
// import './service/axios_demo'
// import hyRequest from './service'

import 'normalize.css'
import './assets/css/index.scss'

const app = createApp(App)
// globalRegister(app)
// setupStore()

app.use(store).use(router).mount('#app')
