
import { defineComponent, ref, watch, toRef, watchEffect } from 'vue'
import useRealBoxHeight from './hooks/useRealBoxHeight'
export default defineComponent({
  props: {
    pageNumber: Number,
    originPercent2: Number
    // isActive: Boolean
  },
  emits: ['scrollEvent'],
  setup(props) {
    const boxRef: any = ref(null)
    const { realboxHeight, realboxWidth } = useRealBoxHeight(boxRef)
    const originPercent = ref()
    watchEffect(() => {
      originPercent.value = props.originPercent2
    })

    return { boxRef, realboxHeight, realboxWidth, originPercent }
  }
})
