<template>
  <div class="page3">
    <div class="video">
      <video
        class=""
        id="video"
        loop
        muted
        controls
        webkit-playsinline="true"
        playsinline="true"
        x5-playsinline="true"
        x5-video-player-type="h5-page"
        ref="video"
        @click.prevent="pauseEvent"
      >
        <source src="~@/assets/video/demo.mp4" type="video/mp4" />
      </video>
      <div class="controls" v-show="showOwnControls" @click.prevent="playEvent">
        <div class="playBtn">
          <img src="~@/assets/img/playbtn.png" alt="" srcset="" />
        </div>
      </div>
    </div>
  </div>
</template>
<script lang="ts">
import { defineComponent, ref } from 'vue'
export default defineComponent({
  setup() {
    const video: any = ref()
    const showOwnControls = ref(true)
    const playEvent = () => {
      video.value.play()
      showOwnControls.value = false
    }
    const pauseEvent = () => {
      video.value.pause()
      showOwnControls.value = true
    }
    return {
      playEvent,
      pauseEvent,
      showOwnControls,
      video
    }
  }
})
</script>
<style scoped lang="scss">
.page3 {
  width: 100%;
  height: 100vh;
  .video {
    width: 100%;
    height: 100%;

    cursor: pointer;
    position: relative;
    #video {
      // object-fit: cover;
      width: 100%;
      height: 100%;
    }
    .controls {
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      position: absolute;
      top: 0;
      left: 0;
      cursor: pointer;
      .playBtn {
        width: 9%;
      }
    }
  }
}
</style>
