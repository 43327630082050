<template>
  <div class="App">
    <keep-alive>
      <router-view></router-view>
    </keep-alive>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
// import 'element-plus/lib/theme-chalk/base.css'
// import 'element-plus/lib/theme-chalk/el-button.css'
// import { ElButton } from 'element-plus'

export default defineComponent({
  name: 'App',
  components: {
    // ElButton
  }
})
</script>

<style lang="scss">
.App {
  // overflow: hidden;
  width: 100%;
  height: 100%;
}
* {
  scroll-behavior: smooth;
}
</style>
