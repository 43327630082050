
import { defineComponent, ref } from 'vue'
export default defineComponent({
  setup() {
    const video: any = ref()
    const showOwnControls = ref(true)
    const playEvent = () => {
      video.value.play()
      showOwnControls.value = false
    }
    const pauseEvent = () => {
      video.value.pause()
      showOwnControls.value = true
    }
    return {
      playEvent,
      pauseEvent,
      showOwnControls,
      video
    }
  }
})
