
import { defineComponent, watchEffect, ref } from 'vue'
export default defineComponent({
  props: {
    guideActiveIndex: Number
  },
  setup(props) {
    const guideArr = ['WORK', 'FAMILY', 'ABOUT US', 'CONTACT']

    const activeIndex = ref()
    watchEffect(() => {
      activeIndex.value = props.guideActiveIndex
    })

    const toPart = (index: number) => {
      let name = ''
      if (index === 0) {
        name = '#work'
      } else if (index === 1) {
        name = '#cohere-family'
      } else if (index === 2) {
        name = '#aboutus'
      } else if (index === 3) {
        name = '#contactus'
      }
      const dom = document.querySelector(name)
      dom!.scrollIntoView({
        behavior: 'smooth',
        block: 'end',
        inline: 'nearest'
      })
      activeIndex.value = index
    }
    return {
      activeIndex,
      guideArr,
      toPart
    }
  }
})
