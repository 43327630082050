<template>
  <div class="guide">
    <ul>
      <li
        v-for="(item, index) in guideArr"
        :key="index"
        :class="activeIndex === index ? 'active' : ''"
        v-html="item"
        @click="toPart(index)"
      ></li>
    </ul>
  </div>
</template>
<script lang="ts">
import { defineComponent, watchEffect, ref } from 'vue'
export default defineComponent({
  props: {
    guideActiveIndex: Number
  },
  setup(props) {
    const guideArr = ['WORK', 'FAMILY', 'ABOUT US', 'CONTACT']

    const activeIndex = ref()
    watchEffect(() => {
      activeIndex.value = props.guideActiveIndex
    })

    const toPart = (index: number) => {
      let name = ''
      if (index === 0) {
        name = '#work'
      } else if (index === 1) {
        name = '#cohere-family'
      } else if (index === 2) {
        name = '#aboutus'
      } else if (index === 3) {
        name = '#contactus'
      }
      const dom = document.querySelector(name)
      dom!.scrollIntoView({
        behavior: 'smooth',
        block: 'end',
        inline: 'nearest'
      })
      activeIndex.value = index
    }
    return {
      activeIndex,
      guideArr,
      toPart
    }
  }
})
</script>
<style scoped lang="scss">
.guide {
  position: sticky;
  top: 0px;
  left: 0px;
  height: 140px;
  width: 100%;
  z-index: 999;
  padding-top: 40px;
  transform: translateZ(11px);
  background-color: black;
  ul {
    // position: sticky;
    // top: 0;
    // left: 0;
    z-index: 999;
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    // padding: 0 40px;
    li {
      // font-weight: bold;
      font-size: 24px;
      line-height: 1.3;
      letter-spacing: -1px;
      color: #999;
      text-align: left;
      display: flex;
      align-items: flex-start;
      justify-content: center;
      cursor: pointer;
      flex: 1;
      text-align: center;
      width: 100%;
      height: 100%;
      // font-family: 'Helvetica';
      // height: 60px;

      &.active {
        color: #b51700;
        letter-spacing: -2px;
        line-height: 1.1;
        font-size: 36px;
      }
    }
  }
}
</style>
