<template>
  <div class="page2 DWB">
    <div class="box" ref="boxRef">
      <!-- <img src="~@/assets/img/word.png" alt="" /> -->
      <div class="abBox">
        <div
          class="realbox"
          :style="{ height: realboxHeight + 'px', width: realboxWidth + 'px' }"
        >
          <div class="row" v-for="index of 26" :key="index">
            <div class="whiteBox" :style="{ width: originPercent + '%' }"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script lang="ts">
import { defineComponent, ref, watch, toRef, watchEffect } from 'vue'
import useRealBoxHeight from './hooks/useRealBoxHeight'
export default defineComponent({
  props: {
    pageNumber: Number,
    originPercent2: Number
    // isActive: Boolean
  },
  emits: ['scrollEvent'],
  setup(props) {
    const boxRef: any = ref(null)
    const { realboxHeight, realboxWidth } = useRealBoxHeight(boxRef)
    const originPercent = ref()
    watchEffect(() => {
      originPercent.value = props.originPercent2
    })

    return { boxRef, realboxHeight, realboxWidth, originPercent }
  }
})
</script>
<style scoped lang="scss">
$picW: 1816;
$picH: 966;
@function posx($x) {
  @return $x / $picW * 100%;
}
@function posy($y) {
  @return $y / $picH * 100%;
}
.page2 {
  width: 100%;
  height: 100vh;
  background-color: #000;
  padding: 56/1920 * 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  .box {
    width: 100%;
    height: 100%;
    max-width: 1920px;
    max-height: 1080px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    background-image: url('~@/assets/img/word.png');
    background-size: contain;
    background-position: center center;
    background-repeat: no-repeat;
    position: relative;
    img {
      object-fit: contain;
      width: 100%;
      height: 100%;
    }
    .abBox {
      width: 100%;
      height: 100%;
      position: absolute;
      left: 0;
      top: 0;

      display: flex;
      align-items: center;
      justify-content: center;
      .realbox {
        width: 100%;
        position: relative;
        // background-co lor: rgba(255, 255, 255, 0.3);
      }
    }
  }
  .row {
    $topRe: 5; //头上露出的高度
    $leftRe: -22; //左边超出的宽度
    $height: 54;
    $rowGap: 25;
    $alllong: 1851;
    position: absolute;
    height: posy($height);
    .whiteBox {
      width: auto;
      height: 100%;
      position: absolute;
      background-color: white;
      top: 0;
      left: 0;
    }
    &:nth-of-type(1) {
      left: posx($leftRe);
      top: posy($topRe);
      width: posx(812);
    }
    &:nth-of-type(2) {
      left: posx(830);
      top: 0.5%;
      width: posx(478);
    }
    &:nth-of-type(3) {
      left: posx($leftRe);
      top: posy($height + $rowGap + $topRe);
      width: posx(936);
    }
    &:nth-of-type(4) {
      left: posx(1215);
      top: posy($height + $rowGap + $topRe);
      width: posx(615);
    }
    &:nth-of-type(5) {
      left: posx($leftRe);
      top: posy(($height + $rowGap) * 2 + $topRe);
      width: posx($alllong);
    }
    &:nth-of-type(6) {
      left: posx($leftRe);
      top: posy(($height + $rowGap) * 3 + $topRe);
      width: posx(1138);
    }
    &:nth-of-type(7) {
      left: posx($leftRe);
      top: posy(($height + $rowGap) * 4 + $topRe);
      width: posx($alllong);
    }
    &:nth-of-type(8) {
      left: posx($leftRe);
      top: posy(($height + $rowGap) * 5 + $topRe);
      width: posx(837);
    }
    &:nth-of-type(9) {
      left: posx(994);
      top: posy(($height + $rowGap) * 5 + $topRe);
      width: posx($alllong - 837 - 176);
    }
    &:nth-of-type(10) {
      left: posx($leftRe);
      top: posy(($height + $rowGap) * 6 + $topRe);
      width: posx(1661);
    }
    $secondGap: 38;
    &:nth-of-type(11) {
      left: posx($leftRe);
      top: posy(($height + $rowGap) * 7 + $topRe + $secondGap);
      width: posx($alllong);
    }
    &:nth-of-type(12) {
      left: posx($leftRe);
      top: posy(($height + $rowGap) * 8 + $topRe + $secondGap);
      width: posx(682);
    }
    &:nth-of-type(13) {
      left: posx(966);
      top: posy(($height + $rowGap) * 8 + $topRe + $secondGap);
      width: posx(862);
    }
    &:nth-of-type(14) {
      left: posx($leftRe);
      top: posy(($height + $rowGap) * 9 + $topRe + $secondGap);
      width: posx($alllong);
    }
    &:nth-of-type(15) {
      left: posx($leftRe);
      top: posy(($height + $rowGap) * 10 + $topRe + $secondGap);
      width: posx($alllong);
    }
    &:nth-of-type(16) {
      left: posx($leftRe);
      top: posy(($height + $rowGap) * 11 + $topRe + $secondGap);
      width: posx($alllong);
    }
  }
}
</style>
<!-- <div class="row">
  <div v-html="ESTABLISHED IN 2019,"></div>
  <div>WE ARE</div>
</div>
<div class="row">
  <div class="2a">FULL-PROCESS</div>
  <div class="2b">CREATIVE</div>
</div>
<div class="row">
  <div>AGENCY COMPOSED BY A GROUP</div>
</div>
<div class="row">
  <div>OF PROFESSIONAL *CREATORS</div>
</div>
<div class="row">
  <div>WITH EXTENSIVE EXPERIENCE IN</div>
</div>
<div class="row">
  <div>THE SPORTS,</div>
  <div>FASHION AND</div>
</div>
<div class="row">
  <div>YOUTH CULTURE INDUSTRIES.</div>
</div> -->
