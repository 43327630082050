
import { defineComponent, computed } from 'vue'
import Guide from './guide.vue'
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'

import useGuide from './hooks/useGuide'

import { Swiper, SwiperSlide } from 'swiper/vue'
// import Swiper core and required modules
import SwiperCore, { Autoplay } from 'swiper'

// install Swiper modules
SwiperCore.use([Autoplay])
// import type SwiperType from 'swiper/types/swiper-class'
import 'swiper/swiper.scss'
export default defineComponent({
  components: { Swiper, SwiperSlide, Guide },
  setup() {
    // 获取数据
    const store = useStore()
    store.dispatch('article/getListAction')
    store.dispatch('article/getWallListAction')
    const list = computed(() => {
      return store.state.article.list.slice(0, 9)
    })
    const wallList = computed(() => {
      return store.state.article.wallList.slice(0, 9)
    })

    //路由相关
    const router = useRouter()
    const toDetail = (index: number) => {
      router.push({
        name: 'workdetail',
        params: {
          id: index
        }
      })
    }
    const toList = () => {
      router.push({
        name: 'worklist'
      })
    }
    // 导航所需
    const guideActiveIndex = useGuide(wallList)

    return { toDetail, toList, list, wallList, guideActiveIndex }
  }
})
