<template>
  <div class="page1 wrapper">
    <div class="logo"><img src="~@/assets/img/cohere.png" alt="" /></div>
    <div class="aniclass">
      <div class="logo2" ref="logo2Ref" :style="`left:calc(${transX})`">
        <img src="~@/assets/img/ishere.png" alt="" srcset="" />
      </div>
    </div>
  </div>
</template>
<script lang="ts">
import { defineComponent, ref, watchEffect, onMounted } from 'vue'
export default defineComponent({
  props: {
    pageNumber: Number,
    originPercent1: Number
  },
  emits: ['scrollEvent'],
  setup(props) {
    const logo2Ref: any = ref(null)
    const originPercent = ref()
    const transX = ref()
    watchEffect(() => {
      originPercent.value = props.originPercent1
      const w = logo2Ref.value?.offsetWidth
      transX.value = `${originPercent.value}% + ${
        (w * originPercent.value) / 100
      }px`
    })

    return {
      logo2Ref,
      transX
    }
  }
})
</script>
<style scoped lang="scss">
.page1 {
  width: 100%;
  height: 100vh;
  background-color: #000;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  overflow: hidden;
  .logo,
  .logo2 {
    width: 1275 / 1920 * 100%;
    position: absolute;
  }
  .aniclass {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    .logo2 {
      transform: translateX(-100%);
    }
  }
}
</style>
