
import { defineComponent } from 'vue'
// import 'element-plus/lib/theme-chalk/base.css'
// import 'element-plus/lib/theme-chalk/el-button.css'
// import { ElButton } from 'element-plus'

export default defineComponent({
  name: 'App',
  components: {
    // ElButton
  }
})
