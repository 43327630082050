<template>
  <div class="guide">
    <ul>
      <li
        v-for="(item, index) in guideArr"
        :key="index"
        :class="activeIndex === index ? 'active' : ''"
        v-html="item"
        @click="toPart(index)"
      ></li>
    </ul>
  </div>
</template>
<script lang="ts">
import { defineComponent, watchEffect, ref } from 'vue'
export default defineComponent({
  props: {
    guideActiveIndex: Number
  },
  setup(props) {
    const guideArr = ['WORK', 'FAMILY', 'ABOUT US', 'CONTACT']

    const activeIndex = ref()
    watchEffect(() => {
      activeIndex.value = props.guideActiveIndex
    })

    const toPart = (index: number) => {
      let name = ''
      if (index === 0) {
        name = '#work'
      } else if (index === 1) {
        name = '#cohere-family'
      } else if (index === 2) {
        name = '#aboutus'
      } else if (index === 3) {
        name = '#contactus'
      }
      const dom = document.querySelector(name)
      dom!.scrollIntoView(false)
    }
    return {
      activeIndex,
      guideArr,
      toPart
    }
  }
})
</script>
<style scoped lang="scss">
.guide {
  position: absolute;
  top: 0px;
  left: 0px;
  height: 100%;
  width: (1920 - 1340)/2/1920 * 100%;
  // background-color: red;
  ul {
    position: sticky;
    top: 0;
    left: 0px;
    height: 100vh;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    li {
      margin-bottom: 100px;
      // font-weight: bold;
      font-size: 20px;
      line-height: 1.3;
      letter-spacing: -1px;
      color: #999;
      text-align: left;
      width: 144px;
      height: 100px;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      // font-family: 'Helvetica';
      // height: 60px;
      @media screen and (max-width: 1600px) {
        font-size: 16px;
      }
      &.active {
        color: #b51700;
        letter-spacing: -2px;
        line-height: 1.3;
        font-size: 30px;
        @media screen and (max-width: 1600px) {
          font-size: 28px;
        }
      }
    }
  }
}
</style>
